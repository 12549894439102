/*Header*/

@media only screen and (min-width:960px) {
    header#header+div.main-container {
        padding-top: 102px !important;
    }
}

#nav>li.nav-item.nav-item--home.level0.level-top.active {
    display: block;
}

.desktop-nav-container {
    border-color: transparent;
}

.nav-item.level0.level-top.nav-8.classic.nav-item--only-subcategories a span {
    background-color: #3953a3;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 300;
    border-radius: 10px;
    padding: 0 10px;
}

.nav-regular li.level0>a>span {
    display: inline-block;
    white-space: nowrap;
    font-weight: 500;
    color: #333;
}

header#header:not(.floating) .module-search.flyout.fly-right .form-search .input-text {
    float: none;
    border: 1px solid #000;
    border-radius: 20px;
}

.module-search.flyout .form-search .input-text {
    background: transparent;
    width: 100px;
    z-index: 1;
    border: none;
    position: relative;
    top: 7px;
    left: 8px;
    height: 25px;
    line-height: 20px;
    padding: 10px;
    color: #000;
    float: left;
}

.form-search .input-text {
    border-radius: 15px;
    border: 2px solid #333
}

header#header:not(.floating) .module-search.flyout .form-search .input-text {
    width: 100%;
}

.module-search .form-search .button-search .fa {
    color: #333 !important;
}

.links>li>a {
    padding: 0 5px;
    color: #333;
}

.header .userCode,
.header .userName,
.header .userSwitch a,
.header .welcome-msg {
    font-weight: 400;
    font-size: 14px;
    color: #333 !important;
    margin: 5px 0 !important;
}

#mini-cart .feature-icon-hover a {
    color: #333;
}

div#mini-cart .hide-below-960 span.cart-total {
    color: #fff;
    background-color: #3953a3;
}

.dropdown .caret {
    opacity: 1;
    display: none;
}

#root-wrapper a:hover span.fa:before {
    color: #333 !important;
}

.button.btn-checkout span,
.button.btn-inline span {
    background-color: #3953a3;
    color: #fff;
}

button,
input,
select,
textarea {
    font-family: "Open Sans";
}

.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span {
    background-color: #333;
}

.header .dropdown-menu a,
.header .form-search .search-autocomplete li,
.header .open>.dropdown-toggle.cover>div a {
    color: #333;
    font-family: "Open Sans";
}

.product-name {
    margin: 0;
    font-weight: 400;
    font-size: 1.2em;
}