/*Footer*/
h6.block-title.heading, #footer ul li a, .footer a .fa {
    color: #fff;
}
h6.block-title.heading {
    font-family: "Open Sans";
    font-size: 1.8em;
    font-weight: 700;
}
#footer ul li a, .footer-social-links {
    font-size: 1.4em;
    font-weight: 400;
    font-family: "Open Sans";
    line-height: 1.4;
}
.footer-bottom-container {
    background-color: #333;
}
.footer a .fa {
    font-size: 40px;
    margin-right: 10px;
    float: right;
}