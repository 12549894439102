/* Home/general styling*/
#cboxClose, #cboxNext, #cboxPrevious, #scroll-to-top, .accordion-style1 .opener, .add-to-links.addto-icons a, .buttons-set .back-link small, .collapsible .opener, .gen-slider-arrows1 .direction-nav a, .gen-slider-arrows1 .direction-nav a.disabled:hover, .gen-slider-arrows2 .direction-nav a, .gen-slider-arrows2 .direction-nav a.disabled:hover, .gen-slider-arrows3 .direction-nav a, .gen-slider-arrows3 .direction-nav a.disabled:hover, .slider-arrows1 .owl-controls .owl-buttons div, .slider-arrows1 .owl-controls.clickable .owl-buttons div.disabled:hover, .slider-arrows2 .owl-controls .owl-buttons div, .slider-arrows3 .owl-controls .owl-buttons div, .slider-arrows3 .owl-controls.clickable .owl-buttons div.disabled:hover, .slider-pagination1 .owl-controls .owl-page span, .slider-pagination2 .owl-controls .owl-page span, .sorter .sort-by .category-asc, .sorter .sort-by .category-desc, .sorter .view-mode a {
    background-color: #3953a3
}
#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption, .feature .heading, .footer .heading, .gen-tabs .tabs a, .heading, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, h1, h2, h3, h3.product-name, h4, h5, h6 {
    font-family: "Open Sans";
    font-weight: 700;
    text-transform: uppercase;
}
body {
    font-family: "Open Sans";
}
/*B2C Home page*/
.links > li > a {
    padding: 0 5px;
    color: #333;
    font-family: "Open Sans";
    text-transform: uppercase;
    font-weight: 600;
  }