/* Category */
.page-type-category .qty-wrapper {
	font-size: 1em;
	margin: 5px 0 10px;
}



.page-type-category .products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:55px;
}

.category-products .toolbar {
	background-color:#f1f1f1;
	font-family:"Open Sans";
}
.sorter .view-mode .list, .sorter .view-mode .grid {
	background-color:#f1f1f1;
}
span.list.active, span.grid.active {
	background-color:#3953a3 !important;
}
button.button span {
   text-transform: uppercase;
   border-radius: 30px;
   background-color: #3953a3;
   color: #fff;
}
.product-view .btn-cart:hover span, button.btn-checkout:hover span, button.button:hover span {
	background-color:#333;
	color:#fff;
	opacity:1;
}

.category-products .toolbar .sorter {
    display: inline-block;
    float: left;
}
.toolbar .pager {
    margin: 0;
    border-bottom: none;
    border-top: 1px dashed #e5e5e5;
    text-align: center;
    display: inline-block;
    float: right;
}
span.list.active, span.grid.active {
    background-color:#3953a3 !important;
    padding-right:5px;
}
.sorter .view-mode a:hover {
   background-color: #333;
}

.item-code {
	font-size:12px;
}
body, button.button span span {
    font-family:"Open Sans" !important;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
    color:#646363;
    font-weight:400;
    letter-spacing:.5px;
    font-family:"Open Sans";
    font-size:18px;
    font-weight:700;
}
li.current {
    background-color:#3953a3 !important;
    color:#fff;
}
.pager .pages li a:hover {
    background-color:#333;
    color:#fff;
}
.breadcrumbs {
   margin-top:3% !important;
}
.product-search-container {
   /*LM commented it margin-top:4%; */
}
.pager {
    margin:0;
}
.page-position {
    float:right;
}